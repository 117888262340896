/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import CookieConsent from "react-cookie-consent"
import { myContext } from "../../provider"

import Header from "./header"
import Footer from "./footer"
import "bootstrap/dist/css/bootstrap.min.css"
import "./style.css"
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      partenaires: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          dir: { regex: "/images/partenaires/" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <myContext.Consumer>
      {context => (
        <div className="py-5">
          <Header siteTitle={data.site.siteMetadata.title} />
          <main>{children}</main>
          <Footer />
          <CookieBar />
        </div>
      )}
    </myContext.Consumer>
  )
}

const CookieBar = () => (
  <myContext.Consumer>
    {context => (
      <CookieConsent
        location="bottom"
        cookieName="gatsby-gdpr-google-analytics"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          color: "white",
          fontSize: "13px",
          backgroundColor: "#228B22",
        }}
        expires={150}
        // acceptOnScroll={true}
        // acceptOnScrollPercentage={25}
        buttonText="J'accepte"
        onAccept={() => {
          context.enableCookie(true)
        }}
        enableDeclineButton
        declineButtonText="Je décline"
        declineButtonStyle={{ fontSize: "10px" }}
      >
        Ce site internet utilise des cookies pour améliorer l'expérience
        utilisateur et à des fins de statistiques.
        {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
      </CookieConsent>
    )}
  </myContext.Consumer>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
