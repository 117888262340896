import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import ContactForm from "../components/contact_form"
import { myContext } from "../../provider"
import { Link } from "gatsby"

const Footer = () => (
  <myContext.Consumer>
    {context => (
      <footer id="contact" className="bg-light">
        <Container className="py-5">
          <div className="pb-4">
            <h4>Envie de réserver ? Des questions ?</h4>
            <p className="lead">
              N'attendez plus et contactez-nous par mail : <br />
              <a href="mailto:contact@cappecheloisirs.com">
                contact@cappecheloisirs.com
              </a>
            </p>
            {/* <p className="lead">
              N'attendez plus et utilisez le formulaire de contact ci-dessous
              pour nous contacter par mail !
            </p> */}
          </div>
          {/* <ContactForm /> */}
        </Container>
        {/* {context.isCookieEnabled && ( */}
        <iframe
          title="gmap"
          allowFullScreen=""
          frameBorder="0"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11547.14429307745!2d-1.437637!3d43.652619!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3818cfd3ecc11e27!2sCap+P%C3%AAche+Loisirs!5e0!3m2!1sfr!2sfr!4v1561380452272!5m2!1sfr!2sfr"
          style={{ width: "100%", height: "50vh" }}
        ></iframe>
        {/* )} */}
        <Container className="py-4">
          <Row>
            <Col className="text-center">
              © Cap Pêche Loisirs {new Date().getFullYear()} |
              {/* Built with
              <a href="https://www.gatsbyjs.org"> Gatsby</a> | */}
              <Link className="mx-1" to="/mentionslegales">
                Mentions légales
              </Link>
            </Col>
          </Row>
        </Container>
      </footer>
    )}
  </myContext.Consumer>
)

export default Footer
