import PropTypes from "prop-types"
import React from "react"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { Navbar, Nav, Row, Col, Container } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faInstagram,
  faFacebook,
  faTripadvisor,
} from "@fortawesome/free-brands-svg-icons"

import { faPhone } from "@fortawesome/free-solid-svg-icons"

const Header = ({ siteTitle }) => (
  <React.Fragment>
    <Navbar fixed="top" collapseOnSelect expand="lg" bg="light" variant="light">
      <Container>
        <Navbar.Brand href="/">{siteTitle}</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Container className="d-flex justify-content-center">
            <Nav className="m-auto">
              <Nav.Link href="/prestations">Prestations</Nav.Link>
              <Nav.Link href="/equipe">Notre équipe</Nav.Link>
              <Nav.Link href="/materiel">Bateaux et matériel</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>
            </Nav>
            <Nav className="m-auto">
              <Nav.Link href="tel:+33675737251">
                <FontAwesomeIcon className="mx-2" icon={faPhone} />
                <small>06 75 73 72 51</small>
              </Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link
                href="https://instagram.com/cappecheloisirs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </Nav.Link>
              <Nav.Link
                href="https://facebook.com/cappecheloisirs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </Nav.Link>
              <Nav.Link
                href="https://www.tripadvisor.fr/Attraction_Review-g641840-d12644730-Reviews-Cap_Peche_Loisirs-Capbreton_Landes_Nouvelle_Aquitaine.html?m=19905"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTripadvisor} size="2x" />
              </Nav.Link>
            </Nav>
          </Container>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </React.Fragment>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
